import React, { useState, FC } from 'react';
import { Collapse, Typography, Link, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const Todo = (props) =>{
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const subject = props.subject;
  const todos = props.todos;
  const baseUrl = props.baseUrl;

  const subjectClickHandler = () =>{
    setVisible(!visible);
  }

  const onClickFile = (url) =>{
    navigate(url);
  }

  return (
    <>
      <Typography 
        className='betrading_message_content'
        onClick={subjectClickHandler}
        sx={{
          color: '#D06960',
          cursor: 'pointer',
          textDecoration: 'underline',
          marginTop: 1
        }}
      >
        {subject}
      </Typography>
      <Collapse in={visible}>
        {
          todos.map((todo, idx) =>{
            return (
              <Link
                key={`todo_${idx}`}
                onClick={(e) => onClickFile(`${baseUrl}/${todo.opportunityId}`)}
                sx={[{
                  cursor: 'pointer',
                  textDecoration: 'none',
                },{
                  '&:hover':{
                    textDecoration: 'underline'
                  }
                }]}
              >
                <Typography className='betrading_message_content' sx={{
                  marginLeft: 3,
                  marginTop: 1,
                  fontSize: 14.5
                }}>
                  {`▶︎ ${todo.name}`}
                </Typography>
              </Link>
            )
          })
        }
      </Collapse>
    </>
  );
};