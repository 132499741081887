import React, {useState, useEffect, useLayoutEffect} from 'react'
import { Controller, useForm, SubmitHandler, useFieldArray } from 'react-hook-form'
import { useNavigate, useLocation } from "react-router-dom";
import { Button, TextField, Hidden, FormControl, InputLabel, Select, MenuItem, Grid, Box, Typography} from '@mui/material';
import {DragDropFileUpload} from '../../../components/DragDropFileUpload';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import {Input, defaultValues, FactoringInfo} from '../../../common/opportunity';
import Loading from "../../../components/Loading";
import { ErrorModal } from '../../../components/ErrorModal';

export const AdminFactoringConfirm = () =>{
  const location = useLocation();
  const [factoringInfo, setFactoringInfo] = useState<FactoringInfo>(location.state);
  const { control, handleSubmit, setValue, getValues} = useForm<Input>({defaultValues});
  const { fields } = useFieldArray({
    control,
    name: 'companies'
  });
  const { fields: otherCompanies, append: otherAppend, remove: otherRemove } = useFieldArray({
    control,
    name: 'otherCompanies'
  });
  const navigate = useNavigate();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const selectedId = query.get('selectedId');
  const draftId = query.get('draftId');
  const [isLoading, setIsLoading] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();

  const handleOpenErrorModal = () =>{
    setErrorModalOpen(!errorModalOpen);
  }

  useEffect(() => {
    setValue('user_id', factoringInfo.inputs.user_id);
    setValue('closedate', dayjs(factoringInfo.inputs.closedate.$d));
    setValue('kiboukingakuSuchi', factoringInfo.inputs.kiboukingakuSuchi);
    setValue('type', factoringInfo.inputs.type);
    setValue('tashariyoureki', factoringInfo.inputs.tashariyoureki);
    setValue('riyoucompany', factoringInfo.inputs.riyoucompany);
    setValue('tsuutyou', factoringInfo.inputs.tsuutyou);
    setValue('otherFile', factoringInfo.inputs.otherFile);
    setValue('goyoubou', factoringInfo.inputs.goyoubou);
    setValue('companies', factoringInfo.inputs.companies.map((com) =>{
      if(com.kaisyuyoteibi) com.kaisyuyoteibi = dayjs(com.kaisyuyoteibi.$d);
      return com;
    }));
    setValue('otherCompanies', factoringInfo.inputs.otherCompanies.map((com) =>{
      return com;
    }));
  }, []);

  const onClickCancel = () =>{
    if(draftId){
      navigate(`/admin/factoring/app/draft?draftId=${draftId}`, {state: factoringInfo});
    }else if(selectedId){
      navigate(`/admin/factoring/app-2?selectedId=${selectedId}`, {state: factoringInfo});
    }else{
      navigate(`/admin/factoring/app/new`, {state: factoringInfo});
    }
  }

  const onSubmit: SubmitHandler<Input> = async (evt) =>{
    return;
  }

  return (
    <Box sx={{pr: {xs: 1, md: 2}, pl: {xs: 1, md: 2}, pb: 2}}>
      <p>この内容で申請しますか？</p>
      <form id="user-confirm-form" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Controller
            control={control}
            name="user_id"
            render={({ field }) => (
              <Hidden/>
            )}
          />
          <Box sx={{
            borderLeft: "5px solid #D16960",
            textAlign: 'left'
          }}>
            <Typography sx={{fontWeight: 'bold', fontSize: 20, ml: 3}}>申請情報</Typography>
            <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3}}>ご希望の取引内容をご入力ください</Typography>
            <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3, color: "#FF0000"}}></Typography>
          </Box>
          <Box
            sx={{backgroundColor: '#F4F6F9', borderRadius: '0px 10px 10px 10px', pt: {xs: 2}, pb: 2, pr: 3, pl: 3, minWidth: {md: '520px'}}}
          >
            <Controller
              control={control}
              name='closedate'
              disabled
              render={({ field }) => (
                <Grid container>
                  <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                    <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                      調達希望日
                    </InputLabel>
                  </Grid>
                  <Grid item md={4.5} sm={12} xs={12} sx={{pr: 2}}>
                    <FormControl margin='normal' fullWidth sx={{mt: {xs: 1, md: 2}}}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                        <DatePicker
                          {...field}
                          format={'YYYY/MM/DD'}
                          onChange={(newValue) =>{if(newValue) setValue('closedate', newValue)}}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid item md={4.5} sm={0} xs={0} sx={{pr: 2}}></Grid>
                </Grid>
              )}
            />
            <Controller
              control={control}
              name="kiboukingakuSuchi"
              disabled
              render={({ field }) => (
                <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    調達希望金額
                  </InputLabel>
                </Grid>
                <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}>
                  <TextField
                    {...field}
                    fullWidth
                    sx={{mt: {xs: 1, md: 2}}}
                    margin="normal"
                    color="secondary"
                  />
                </Grid>
                <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}></Grid>
              </Grid>
              )}
            />
            <Controller
              control={control}
              name="type"
              disabled
              render={({ field }) => (
                <Grid container>
                  <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                    <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                      契約方法
                    </InputLabel>
                  </Grid>
                  <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}>
                    <FormControl fullWidth margin='normal' sx={{mt: {xs: 1, md: 2}}}>
                      <Select
                        {...field}
                        color="secondary"
                      >
                        <MenuItem value='' sx={{color:'gray'}}>選択してください</MenuItem>
                        <MenuItem value={'2者間'}>2者間</MenuItem>
                        <MenuItem value={'3者間'}>3者間</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}></Grid>
                </Grid>
              )}
            />
            <Controller
            control={control}
              disabled
              name="goyoubou"
              defaultValue=""
              render={({ field }) => (
                <Grid container>
                  <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                    <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                      ご要望等
                    </InputLabel>
                  </Grid>
                  <Grid item md={9} sm={12} xs={12} sx={{pr: 2}}>
                    <TextField
                      {...field}
                      fullWidth
                      sx={{mt: {xs: 1, md: 2}}}
                      margin="normal"
                      color="secondary"
                      multiline
                      rows={3}
                    />
                  </Grid>
                </Grid>
              )}
            />
          </Box>
            {fields.map((field, index) =>(
              <Box key={`comp_${index}`}>
              <Box sx={{
                borderLeft: "5px solid #D16960",
                textAlign: 'left',
                mt: 2
              }}>
                <Typography sx={{fontWeight: 'bold', fontSize: 20, ml: 3}}>売掛先情報（{index + 1}）</Typography>
                <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3}}>売却したい売掛金の情報をご入力ください</Typography>
                <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3, color: "#FF0000"}}></Typography>
              </Box>
              <Box sx={{backgroundColor: '#F4F6F9', borderRadius: '0px 10px 10px 10px', pt: {xs: 2}, pb: 2, pr: 3, pl: 3, minWidth: {md: '520px'}}}>
              <Box key={field.id} sx={{backgroundColor: '#F4F6F9'}}>
                <Controller
                  disabled
                  control={control}
                  name={`companies.${index}.company`}
                  defaultValue=""
                  render={({ field }) => (
                    <Grid container>
                      <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                        <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                          会社名
                        </InputLabel>
                      </Grid>
                      <Grid item md={9} sm={12} xs={12} sx={{pr: 2}}>
                        <TextField
                          {...field}
                          fullWidth
                          sx={{mt: {xs: 1, md: 2}}}
                          margin="normal"
                          color="secondary"
                        />
                      </Grid>
                    </Grid>
                  )}
                />
                <Controller
                  control={control}
                  name={`companies.${index}.sfid`}
                  render={({ field }) => (
                    <Hidden
                    />
                  )}
                />
                <Controller
                  disabled
                  control={control}
                  name={`companies.${index}.seikyuukingaku`}
                  render={({ field }) => (
                    <Grid container>
                      <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                        <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                          請求金額
                        </InputLabel>
                      </Grid>
                      <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}>
                        <TextField
                          {...field}
                          fullWidth
                          sx={{mt: {xs: 1, md: 2}}}
                          margin="normal"
                          color="secondary"
                        />
                      </Grid>
                      <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}></Grid>
                    </Grid>
                  )}
                />
                <Controller
                  disabled
                  control={control}
                  name={`companies.${index}.kaisyuyoteibi`}
                  render={({ field }) => (
                    <Grid container>
                      <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                        <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                          売掛先からの入金予定日
                        </InputLabel>
                      </Grid>
                      <Grid item md={4.5} sm={12} xs={12} sx={{pr: 2}}>
                        <FormControl margin='normal' fullWidth >
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                            <DatePicker
                              {...field}
                              format={'YYYY/MM/DD'}
                              onChange={(newValue) =>{if(newValue) setValue(`companies.${index}.kaisyuyoteibi`, newValue)}}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </Grid>
                      <Grid item md={4.5} sm={0} xs={0} sx={{pr: 2}}></Grid>
                    </Grid>
                  )}
                />
                <Controller
                  control={control}
                  name={`companies.${index}.seikyuusyo`}
                  render={({ field: {onChange, value }}) =>(
                    <Grid container>
                      <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                        <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                          請求書
                        </InputLabel>
                        <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                          請求書、注文書、契約書など売掛金の内容が分かる書類をご提出ください
                        </InputLabel>
                      </Grid>
                      <Grid item md={9} sm={12} xs={12} sx={{pr: 2}}>
                        <FormControl margin='normal' fullWidth sx={{mt: {xs: 1, md: 2}}}>
                          <DragDropFileUpload onFileUpload={onChange} input_id={1} inputValue={value} disabled/>
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                />
              </Box>
              </Box>
              </Box>
            ))}
          <Box sx={{
            borderLeft: "5px solid #D16960",
            textAlign: 'left',
            mt: 2
          }}>
            <Typography sx={{fontWeight: 'bold', fontSize: 20, ml: 3}}>その他取引先情報</Typography>
            <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3}}>その他お取引のある会社情報をご入力ください</Typography>
            <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3, color: "#FF0000"}}></Typography>
          </Box>
          <Box
            sx={{backgroundColor: '#F4F6F9', borderRadius: '0px 10px 10px 10px', pt: {xs: 2}, pb: 2, pr: 3, pl: 3, minWidth: {md: '520px'}}}
          >
            {otherCompanies.map((field, index) =>(
              <Box key={field.id} sx={{backgroundColor: '#F4F6F9'}}>
                <Controller
                  disabled
                  control={control}
                  name={`otherCompanies.${index}.company`}
                  render={({ field }) => (
                    <Grid container>
                      <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                        <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                          会社名
                        </InputLabel>
                      </Grid>
                      <Grid item md={9} sm={12} xs={12} sx={{pr: 2}}>
                        <TextField
                          {...field}
                          fullWidth
                          sx={{mt: {xs: 1, md: 2}}}
                          margin="normal"
                          color="secondary"
                        />
                      </Grid>
                    </Grid>
                  )}
                />
                <Controller
                  control={control}
                  name={`otherCompanies.${index}.sfid`}
                  render={({ field }) => (
                    <Hidden
                    />
                  )}
                />
              </Box>
            ))}
          </Box>
          <Box sx={{
            borderLeft: "5px solid #D16960",
            textAlign: 'left',
            mt: 2
          }}>
            <Typography sx={{fontWeight: 'bold', fontSize: 20, ml: 3}}>提出書類</Typography>
            <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3}}>審査に必要な書類をご提出ください</Typography>
            <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3, color: "#FF0000"}}></Typography>
          </Box>
          <Box
            sx={{backgroundColor: '#F4F6F9', borderRadius: '0px 10px 10px 10px', pt: {xs: 2}, pb: 2, pr: 3, pl: 3, minWidth: {md: '520px'}}}
          >
            <Controller
              control={control}
              name='tsuutyou'
              render={({ field: {onChange, value }}) =>(
                <Grid container>
                  <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                    <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                      通帳（表紙付直近2か月分）
                    </InputLabel>
                    <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                      売掛先から入金がある通帳をご提出ください
                    </InputLabel>
                  </Grid>
                  <Grid item md={9} sm={12} xs={12} sx={{pr: 2}}>
                    <FormControl margin='normal' fullWidth sx={{mt: {xs: 1, md: 2}}}>
                      <DragDropFileUpload onFileUpload={onChange} input_id='tsuutyou1' inputValue={value} disabled/>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            />
            <Controller
              control={control}
              name='otherFile'
              render={({ field: {onChange, value }}) =>(
                <Grid container>
                  <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                    <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                      その他書類
                    </InputLabel>
                    <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                      日報や納品書など売掛金の存在を確認できる補足書類がございましたらご提出ください
                    </InputLabel>
                  </Grid>
                  <Grid item md={9} sm={12} xs={12} sx={{pr: 2}}>
                    <FormControl margin='normal' fullWidth sx={{mt: {xs: 1, md: 2}}}>
                      <DragDropFileUpload onFileUpload={onChange} input_id='other1' inputValue={value} disabled/>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            />
          </Box>
          <Box sx={{position: 'sticky', bottom: 3, width: '100%', left: {xs: 0, md: '15vh'}, mt: 2}}>
            <Button
              variant='contained'
              color='warning'
              onClick={() => onClickCancel()}
              sx={{borderRadius: 5,width: {md: '30%', xs: '45%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}, mr: 1}}
            >
              戻る
            </Button>
          </Box>
        </div>
      </form>
      {isLoading ? <Loading inverted={isLoading}/> : <></>}
      <ErrorModal message={errorMessage} modalOpen={errorModalOpen}></ErrorModal>
    </Box>
  )
}