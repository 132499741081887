import React from 'react';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';

// ユーザー
interface User {
  id: number
  uid: string
  provider: string
  email: string
  name: string
  nickname?: string
  image?: string
  isSmsVerified?: boolean
  allowPasswordChange: boolean
  created_at: Date
  updated_at: Date
  smsVerifiedAt?: any
}

export interface AuthContextType{
  isSignedIn: boolean;
  currentUser: User | undefined;
  signin: (user:User, callback:()=>void) => void;
  signout: (callback:() => void) => void;
}

const AuthContext = React.createContext({} as AuthContextType)

export const useAuthContext = ():AuthContextType =>{
  return React.useContext<AuthContextType>(AuthContext);
}

interface Props {
  children: React.ReactNode
}

export const AuthUserProvider = (props:Props) =>{
  const [isSignedIn, setIsSignedIn] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState<User | undefined>();

  const signin = (newUser: User, callback: () => void) =>{
    setIsSignedIn(true);
    setCurrentUser(newUser);
    const ids = [newUser.id]
    Cookies.set('currentUserId', newUser.id);
    Cookies.set('isSignedIn', true);
    Cookies.set('loginUserIds', JSON.stringify(ids));
    Cookies.set('isSMSVerified', newUser.isSmsVerified);
    callback();
  }

  const signout = (callback: () => void) =>{
    setCurrentUser(undefined);
    setIsSignedIn(false);
    Cookies.remove('currentUserId');
    Cookies.remove('isSignedIn');
    Cookies.remove('loginUserIds');
    Cookies.remove('isSMSVerified');
    Cookies.remove('_access_token');
    Cookies.remove('_client');
    Cookies.remove('_uid');
    Cookies.remove('_users');
    Cookies.remove('_contact');
    Cookies.remove('_header_text');
    Cookies.remove('smsVerifiedAt');
    callback();
  }

  const value:AuthContextType = { isSignedIn, currentUser, signin, signout};

  return (
    <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
  );
}