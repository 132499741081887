import { Form } from "react-router-dom";
import {client} from "./client"
import Cookies from 'js-cookie';

interface Bank {
  bankname: string;
}

interface Branch{
  bankCode: string;
  branchName: string;
}

interface Corporate {
  name: string;
}

interface CorporateNum{
  code: string;
}

// 動作確認用
export const getUsers = (params?) => {
  return client.get(`/admin/profile${params}`, {
    headers: {
      "access-token": Cookies.get("_admin_access_token"),
      client: Cookies.get("_admin_client"),
      uid: Cookies.get("_admin_uid"),
    },
  })
}

export const getProfile = () =>{
  const id = Cookies.get('adminCurrentUserId');
  return client.get(`/admin/profile/${id}`, {
    headers: {
      "access-token": Cookies.get("_admin_access_token"),
      client: Cookies.get("_admin_client"),
      uid: Cookies.get("_admin_uid"),
    },
  })
}

export const getProfileContact = () =>{
  const id = Cookies.get('adminCurrentUserId');
  return client.get(`/admin/profile/contact?user_id=${id}`, {
    headers: {
      "access-token": Cookies.get("_admin_access_token"),
      client: Cookies.get("_admin_client"),
      uid: Cookies.get("_admin_uid"),
    },
  });
}

export const getNotifications = () =>{
  return client.get("/admin/notification", {
    headers: {
      "access-token": Cookies.get("_admin_access_token"),
      client: Cookies.get("_admin_client"),
      uid: Cookies.get("_admin_uid"),
    },
  })
}

export const getCollections = (span: number) =>{
  const id = Cookies.get('adminCurrentUserId');
  return client.get(`/admin/collection?span=${span}}&user_id=${id}`, {
    headers: {
      "access-token": Cookies.get("_admin_access_token"),
      client: Cookies.get("_admin_client"),
      uid: Cookies.get("_admin_uid"),
    },
  })
}

export const getTodos = () =>{
  const id = Cookies.get('adminCurrentUserId');
  return client.get(`/admin/todo?user_id=${id}`, {
    headers: {
      "access-token": Cookies.get("_admin_access_token"),
      client: Cookies.get("_admin_client"),
      uid: Cookies.get("_admin_uid"),
    },
  })
}

export const getCollectionNotifications = () =>{
  const id = Cookies.get('adminCurrentUserId');
  return client.get(`/admin/collection/notifications?user_id=${id}`, {
    headers: {
      "access-token": Cookies.get("_admin_access_token"),
      client: Cookies.get("_admin_client"),
      uid: Cookies.get("_admin_uid"),
    },
  })
}

export const getFile = (id: string) =>{
  return client.get(`/admin/s3file/${id}`, {
    headers: {
      "access-token": Cookies.get("_admin_access_token"),
      client: Cookies.get("_admin_client"),
      uid: Cookies.get("_admin_uid"),
    },
  });
}

export const getOpportunityById = (id: string) =>{
  return client.get(`/admin/opportunity/${id}`, {
    headers: {
      "access-token": Cookies.get("_admin_access_token"),
      client: Cookies.get("_admin_client"),
      uid: Cookies.get("_admin_uid"),
    },
  });
}

export const getAgreements = () => {
  const id = Cookies.get('adminCurrentUserId');
  return client.get(`/admin/agreement?user_id=${id}`, {
    headers: {
      "access-token": Cookies.get("_admin_access_token"),
      client: Cookies.get("_admin_client"),
      uid: Cookies.get("_admin_uid"),
    },
  })
}

export const getAgreement = (opportunity_id: string) =>{
  return client.get(`/admin/opportunity/${opportunity_id}`, {
    headers: {
      "access-token": Cookies.get("_admin_access_token"),
      client: Cookies.get("_admin_client"),
      uid: Cookies.get("_admin_uid"),
    },
  })
}

export const getOpportunities = () => {
  const id = Cookies.get('adminCurrentUserId');
  return client.get(`/admin/opportunity?user_id=${id}`, {
    headers: {
      "access-token": Cookies.get("_admin_access_token"),
      client: Cookies.get("_admin_client"),
      uid: Cookies.get("_admin_uid"),
    },
  })
}


export const getAccountsReceivables = () => {
  const id = Cookies.get('adminCurrentUserId');
  return client.get(`/admin/accounts_receivable?user_id=${id}`, {
    headers: {
      "access-token": Cookies.get("_admin_access_token"),
      client: Cookies.get("_admin_client"),
      uid: Cookies.get("_admin_uid"),
    },
  });
}

export const getAccountsReceivable = (id: string) =>{
  return client.get(`/admin/accounts_receivable/${id}`, {
    headers: {
      "access-token": Cookies.get("_admin_access_token"),
      client: Cookies.get("_admin_client"),
      uid: Cookies.get("_admin_uid"),
    },
  });
}

export const getDraftOpportunityByUserId = () => {
  const id = Cookies.get('adminCurrentUserId');
  return client.get(`/admin/draft_opportunity/user_id?user_id=${id}`, {
    headers: {
      "access-token": Cookies.get("_admin_access_token"),
      client: Cookies.get("_admin_client"),
      uid: Cookies.get("_admin_uid"),
    },
  })
}

export const getDraftOpportunityById = (draft_id: string) => {
  return client.get(`/admin/draft_opportunity/${draft_id}`, {
    headers: {
      "access-token": Cookies.get("_admin_access_token"),
      client: Cookies.get("_admin_client"),
      uid: Cookies.get("_admin_uid"),
    },
  })
}

export const getBanks = (params: Bank) => {
  return client.get(`/admin/bank_code/search_bank?bankname=${params.bankname}`, {
    headers: {
      "access-token": Cookies.get("_admin_access_token"),
      client: Cookies.get("_admin_client"),
      uid: Cookies.get("_admin_uid"),
    },
  });
}

export const getBranches = (params: Branch) =>{
  return client.get(`/admin/bank_code/search_branch?bankcode=${params.bankCode}&branchname=${params.branchName}`, {
    headers: {
      "access-token": Cookies.get("_admin_access_token"),
      client: Cookies.get("_admin_client"),
      uid: Cookies.get("_admin_uid"),
    },
  });
}

export const getCorporates = (params: Corporate) => {
  return client.get(`/admin/corporate_name/search?name=${params.name}`, {
    headers: {
      "access-token": Cookies.get("_admin_access_token"),
      client: Cookies.get("_admin_client"),
      uid: Cookies.get("_admin_uid"),
    },
  });
}

export const getCorporateByCode = (params: CorporateNum) =>{
  return client.get(`/admin/corporate_name/search?code=${params.code}`, {
    headers: {
      "access-token": Cookies.get("_admin_access_token"),
      client: Cookies.get("_admin_client"),
      uid: Cookies.get("_admin_uid"),
    },
  });
}

export const putSuspension = (id: string) =>{
  const formData = new FormData();
  formData.append('profile[id]', id);
  return client.put(`/admin/profile/${id}/suspension`, formData, {
    headers: {
      "access-token": Cookies.get("_admin_access_token"),
      client: Cookies.get("_admin_client"),
      uid: Cookies.get("_admin_uid"),
    },
  });
}

export const putUnsuspension = (id: string) =>{
  const formData = new FormData();
  formData.append('profile[id]', id);
  return client.put(`/admin/profile/${id}/unsuspension`, formData, {
    headers: {
      "access-token": Cookies.get("_admin_access_token"),
      client: Cookies.get("_admin_client"),
      uid: Cookies.get("_admin_uid"),
    },
  });
}

export const putForcedWithdrawal = (id: string) =>{
  const formData = new FormData();
  formData.append('profile[id]', id);
  return client.put(`/admin/profile/${id}/forced_withdrawal`, formData, {
    headers: {
      "access-token": Cookies.get("_admin_access_token"),
      client: Cookies.get("_admin_client"),
      uid: Cookies.get("_admin_uid"),
    },
  });
}