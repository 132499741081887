import {client} from "./client";
import Cookies from "js-cookie";

// サインアップ
export const signUp = (params) => {
  return client.post("/auth", params);
};

// サインイン
export const signIn = (params) => {
  return client.post("/auth/sign_in", params);
};

// passwordリセット関連
export const passwordReset = (params) =>{
  return client.post('/auth/password', params);
}

// confirmの再送
export const confirmReset = (params) =>{
  return client.post('/auth/confirmation', params);
}

export const passwordUpdate = (params) =>{
  return client.patch('/auth/password', params);
}

export const googleOauth = () =>{
  return client.post('/auth/google_oauth2');
}

// passcode送信
export const passcodde = (params) =>{
  return client.post('/auth/lead/passcode', params);
}

export const userid = (params) =>{
  return client.post('/auth/user/loginid', params);
}

// サインアウト
export const signOut = () => {
  return client.delete("/auth/sign_out", {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
};

// ログインユーザーの取得
export const getCurrentUser = () => {
  if (
    !Cookies.get("_access_token") ||
    !Cookies.get("_client") ||
    !Cookies.get("_uid")
  )
    return;

  return client.get("/auth/user/sessions", {
    headers: {
      "access-token": Cookies.get("_access_token"),
      client: Cookies.get("_client"),
      uid: Cookies.get("_uid"),
    },
  });
};