import React, { useState, FC } from 'react';
import { Box, Collapse, Typography } from '@mui/material';

export const Notification = (props) =>{
  const [visible, setVisible] = useState(false);
  const subject = props.subject;
  const body = props.body;

  const subjectClickHandler = () =>{
    setVisible(!visible);
  }

  return (
    <>
      <Typography onClick={subjectClickHandler} className='betrading_message_content' sx={{
        marginLeft: 3,
        marginTop: 1,
        fontSize: 14.5
      }}>
        <Box component='span'
          sx={{
            color: '#D06960'
          }}
        >
          {visible ? `▼ ` : `▶︎ `}
        </Box>
        <Box component='span' sx={{cursor: 'pointer'}}>
          {subject}
        </Box>
      </Typography>
      <Collapse in={visible}>
        <Typography className='betrading_message_content'sx={{
          marginLeft: 3,
          marginTop: 1,
          fontSize: 14.5
        }}>
          {body}
        </Typography>
      </Collapse>
    </>
  );
};