import React, { useEffect, useState } from 'react';
import { Typography, Box, Button } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ErrorModal } from './ErrorModal';

export const BottomButtons = () =>{
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();
  const [contactInfo, setContactInfo] = useState({bankname: '', bankinfo: '', accountname: '', accountnamekana: '', phone: ''});
  const [isViewContact, setIsViewContact] = useState(false);

  useEffect(() =>{
    if (Cookies.get('isSignedIn') == 'true' && Cookies.get('isSMSVerified') == 'true'){
      const contact = JSON.parse(Cookies.get('_contact'));
      if(contact){
        setContactInfo(contact);
        setIsViewContact(true);
      }
    }else{
      setIsViewContact(false);
    }
  }, [location.pathname]);

  const handleOpenModal = () =>{
    setModalOpen(!modalOpen);
  }

  return (
    <>
      {Cookies.get('isSignedIn') && Cookies.get('isSignedIn') == 'true' && Cookies.get('isSMSVerified') == 'true' ?
        <>
          <Box sx={{mt: 5, pl: 3, pr: 3, display: {xs: 'flex', md: 'none'}}}>
            <Box
              sx={{width: "30%"}}
            >
              <Box
                onClick={() => navigate('/profile')}
                sx={{
                  width: "100%",
                  borderRadius: 2,
                  backgroundColor: "#FFFFFF"
                }}
              >
                <Box component="img" src="/icons/icon_on_profile.svg" sx={{width: '100%', p: 2}}/>
              </Box>
              <Typography sx={{width: "100%", textAlign: 'center'}}>会員情報</Typography>
            </Box>
            <Box
              sx={{width: "30%", ml: 3}}
            >
              <Box
                onClick={() => navigate('/factoring/top')}
                sx={{
                  width: "100%",
                  borderRadius: 2,
                  backgroundColor: "#FFFFFF",
                }}
              >
                <Box component="img" src="/icons/icon_on_factoring.svg" sx={{width: '100%', p: 2}}/>
              </Box>
              <Typography sx={{width: "100%", textAlign: 'center'}}>審査の<br/>申し込み</Typography>
            </Box>
            <Box
              sx={{width: "30%", ml: 3}}
            >
              <Box
                onClick={() => navigate('/file_addition_list')}
                sx={{
                  borderRadius: 2,
                  width: "100%",
                  backgroundColor: "#FFFFFF"
                }}
              >
                <Box component="img" src="/icons/icon_on_document.svg" sx={{width: '100%', p: 2}}/>
              </Box>
              <Typography sx={{width: "100%", textAlign: 'center'}}>追加で<br/>書類提出</Typography>
            </Box>
          </Box>
          {isViewContact ? 
          <>
            {contactInfo.bankname ?
            <Button 
              variant='contained'
              color='secondary'
              onClick={handleOpenModal}
              sx={{display: {xs: 'initial', md: 'none'}, borderRadius: 5, width: '45%', fontWeight: 'bold', mt: {xs: 5, md: 0}, mb: {xs: 1, md: 0}}}
            >
              振込先を確認
            </Button>
            :
            <></>
            }
            {contactInfo.bankname ?
            <ErrorModal message={<><Typography>＜振込先口座＞</Typography><Typography>{contactInfo.bankname}</Typography><Typography>{contactInfo.bankinfo}</Typography><Typography>{contactInfo.accountnamekana}</Typography></>} modalOpen={modalOpen} />
            :
            <></>
            }
            {contactInfo.phone && contactInfo.phone != '-' ? 
            <Button 
              component={Link}
              color='primary'
              variant='contained'
              to={`tel:${contactInfo.phone}`}
              sx={{display: {xs: 'inline-block', md: 'none'}, height: '40px', borderRadius: 5, width: '45%', fontWeight: 'bold', mt: {xs: 5, md: 0}, mb: {xs: 1, md: 0}}}
            >
              電話する
            </Button>
            :
            <></>
            }
          </>
          :
          <></>
          }
        </>
      :
      <></>
      }
    </>
  );
};