import React, { useEffect } from 'react';
import { Button, TextField, Link, Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Cookies from 'js-cookie';
import { getLeadById, updateLead } from '../../lib/api/lead';
import { passcodde } from '../../lib/api/auth';
import Loading from "../../components/Loading";
import { ErrorModal } from '../../components/ErrorModal';

const validationRules = {
  code:{
    required: '認証コードを入力してください'
  }
}

export const Passcode = () =>{
  const { control, handleSubmit, setError} = useForm({defaultValues: {code: ''}});
  const [phonenum, setPhonenum] = useState();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();

  const handleOpenErrorModal = () =>{
    setErrorModalOpen(!errorModalOpen);
  }

  const handleGetLead = async () =>{
    const res = await getLeadById();
    if(res.status == 200 && res.data.record.mobilephoneC){
      setPhonenum(res.data.record.mobilephoneC);
    }else{
      navigate('/sign_up/info');
    }
  }
  useEffect(() =>{
    handleGetLead();
  }, [])

  const onSubmit = async(evt) =>{
    if(isLoading) return;
    setIsLoading(true);
    evt['email'] = Cookies.get('_uid');
    try{
      const res = await passcodde(evt);
      setIsLoading(false);
      if(res.status == 200){
        const users = Cookies.get("_users") ? JSON.parse(Cookies.get("_users")) : [];
        const indx = users.findIndex((u) => u._uid == Cookies.get('_uid'));
        if(indx >= 0){
          users[indx]._is_sms_verified = true;
          Cookies.set("_users", JSON.stringify(users));
        }
        Cookies.set('isSMSVerified', true);
        Cookies.set('smsVerifiedAt', res.data.verifiedAt);
        navigate("/member/" +  Cookies.get('currentUserId'));
      }else{
        setErrorMessage(<Typography>処理に失敗しました。再度、次へをクリックしてください。</Typography>);
        handleOpenErrorModal();
      }
    }catch(e){
      console.log(e.response);
      setIsLoading(false);
      const response = e.response;
      if(response.status == 400){
        setError('code', { type: 'unmatch', message: response.data.errors});
      }
    }
  }

  const handleReSend = async(evt) =>{
    if(isLoading) return;
    setIsLoading(true);
    try{
      const res = await updateLead({email: Cookies.get('_uid')});
      setIsLoading(false);
      if(res.status == 200){
        setErrorMessage(<Typography>再送しました。</Typography>);
        handleOpenErrorModal();
      }else{
        setErrorMessage(<Typography>再送に失敗しました。再度、実行してください。</Typography>);
        handleOpenErrorModal();
      }
    }catch(e){
      console.log(e);
      setIsLoading(false);
      setErrorMessage(<Typography>再送に失敗しました。再度、実行してください。</Typography>);
      handleOpenErrorModal();
    }
  }

  return (
    <Box
      sx={{
        pr: {xs: 1, md: 2},
        pl: {xs: 1, md: 2},
        pb: 2, 
        pt: {xs: 2}, 
        backgroundColor: {xs: "#FFFFFF"}, 
        borderRadius: 2,
        width: {md: '70%', xs: '100%'},
        margin: 'auto'
    }}>
      <Box component='h2'>認証コードを入力</Box>
      <Typography sx={{textAlign: 'left'}}>
        {phonenum} 宛にSMSを送信しました。
      </Typography>
      <Typography sx={{textAlign: 'left'}}>
        SMSに記載された４桁の数字を入力してください。
      </Typography>
      <Typography sx={{textAlign: 'left'}}>
        ※しばらく経ってもSMSが届かない場合、迷惑メールのフィルター設定でSMSの受信拒否設定などがされていないかをご確認ください。
      </Typography>
      <form id="passcode-form" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name='code'
          rules={validationRules['code']}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              margin="normal"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
            />
          )}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{borderRadius: 5, width: {md: '30%', xs: '80%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}}}
        >
          送信
        </Button>
        <Box></Box>
        <Link onClick={handleReSend} underline="hover">認証コードを再送する</Link>
      </form>
      {isLoading ? <Loading inverted={isLoading}/> : <></>}
      <ErrorModal message={errorMessage} modalOpen={errorModalOpen}></ErrorModal>
    </Box>
  );
}