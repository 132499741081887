import React, {useState} from 'react';
import { Button, FormControl, Select, InputLabel, MenuItem, TextField, Autocomplete, Hidden, FormHelperText, Grid, Box, Typography, Paper, Divider, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import FactoringFormTypes from '../../../enums/factoringFormTypes';
import {DragDropFileUpload} from '../../../components/DragDropFileUpload';
import {Input, defaultValues, defaultCompValue, FactoringInfo, defaultOtherCompValue, validationRules, Company, OtherCompany} from '../../../common/opportunity';
import dayjs from 'dayjs';
import { AccountsReceivableModal } from '../../../components/AccountsReceivableModal';
import Cookies from 'js-cookie';
import Loading from '../../../components/Loading';
import { weekdayTexts, CustomDatePickerToolbar } from '../../../common/common';
import { ErrorModal } from '../../../components/ErrorModal';
import { ConfirmModal } from '../../../components/ConfirmModal';
import { Element, scroller} from 'react-scroll';
import { getAccountsReceivables, getDraftOpportunityById, getOpportunityById, getProfile } from '../../../lib/api/admin';

interface AccountsReceivables{
  account: string;
  accountsreceivable: string;
  closingdate: string;
  corporationnumber: string;
  id: string;
  postalcode: string;
  type: string;
  companyname: string;
  name: string;
  paymentdate: string;
  personincharge: string;
  sfid: string;
  state: string;
  city: string;
  telno: string;
  hp: string;
  street: string;
  other: string;
}

interface selectCompany{
  label: string;
  id: string;
  optLabel?: string;
}

export const AdminFactoringEdit = () =>{
  const location = useLocation();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  // selectedidが存在する場合、コピーする。
  const selectedId = query.get('selectedId');
  const type = query.get('type');
  const clear = query.get('clear');
  const [modalOpen, setModalOpen] = useState(false);
  // draftIdが存在する場合、コピーする。
  // draftIdの場合、商談を更新する。
  const draftId = query.get('draftId');
  const { control, handleSubmit, setValue, getValues} = useForm<Input>({defaultValues});
  const [ accountsReceivables , setAccountsReceivables] = useState<AccountsReceivables[]>([]);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'companies'
  });
  const { fields: otherCompanies, append: otherAppend, remove: otherRemove } = useFieldArray({
    control,
    name: 'otherCompanies'
  });
  const [initialCompanyIds, setInitialCompanyIds] = useState<selectCompany[]>([]);
  const [initialOtherCompanyIds, setInitialOtherCompanyIds] = useState<selectCompany[]>([]);
  const navigate = useNavigate();
  const [returnInfo, setReturnInfo] = useState<FactoringInfo>(location.state);
  const [closedateError, setClosedateError] = useState('');
  const [kaisyuyoteibiError, setKaisyuyoteibiError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pastCompany, setPastCompany] = useState<Company[]>([]);
  const [pastOtherCompany, setPastOtherCompany] = useState<OtherCompany[]>([]);
  const [isLoad, setIsLoad] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState<any>();
  const [confirmButtonLength, setConfirmButtonLength] = useState(1);

  const handleOpenErrorModal = () =>{
    setErrorModalOpen(!errorModalOpen);
  }

  const handleOpenConfirmModal = () =>{
    setConfirmMessage(<Typography>保存しました</Typography>);
    setConfirmButtonLength(1);
    setConfirmModalOpen(true);
  }

  const handleOpenSelectConfirmModal = () =>{
    if(isLoading) return;
    setIsLoading(true);
    setConfirmMessage(<Typography>※添付ファイルは一時保存できません。一時保存してよろしいでしょうか？</Typography>)
    setConfirmButtonLength(2);
    setConfirmModalOpen(true);
  }

  const handleOkClick = () =>{
    setConfirmModalOpen(false);
    onClickDraftSave();
  }

  const handleCancelClick = () =>{
    setConfirmModalOpen(false);
    setIsLoading(false);
  }

  const handleCloseConfirmModal = () =>{
    setIsLoading(false);
    setConfirmModalOpen(false);
    navigate("/admin/factoring/top");
  }

  const handleGetAccountsReceivables = async() =>{
    const res = await getAccountsReceivables();
    const profileRes = await getProfile();
    if(profileRes.status == 200){
      const lastStatus = profileRes.data.record.lastOppStatus;
      if(!profileRes.data.record.identification){
        navigate("/admin/factoring/top");
      }else if(lastStatus && lastStatus != '見込み' && lastStatus != 'NG' && lastStatus != 'キャンセル' && lastStatus != '実行済み'){
        navigate("/admin/factoring/top");
      }
    }
    if(res.status === 200){
      setAccountsReceivables(res.data.records);
    }
  }
  const handleGetOpportunity = async (pid: string) => {
    if(clear) return;
    const res = await getOpportunityById(pid);
    if (res.status === 200) {
      if(res.data.record.closedate) setValue('closedate', dayjs(res.data.record.closedate));
      if(res.data.record.kiboukingakuSuchi) setValue('kiboukingakuSuchi', res.data.record.kiboukingakuSuchi.toLocaleString());
      if(res.data.record.type) setValue('type', res.data.record.type);
      if(res.data.record.note) setValue('goyoubou', res.data.record.note);
      // appendで会社別のデータは追加する
      if(res.data.record.selectedAccountsReceivables.length > 0){
        setPastCompany(res.data.record.selectedAccountsReceivables.filter((sar) => sar.salesflg).map(sar =>{
          const remarkString = ` (締日：${sar.closingdate}、支払日：${sar.paymentdate})`; 
          return {
            company: !sar.companyname ? sar.name + remarkString : sar.companyname + remarkString,
            sfid: sar.id,
            seikyuukingaku: '',
            kaisyuyoteibi: null
          }
        }));
        setPastOtherCompany(res.data.record.selectedAccountsReceivables.filter((sar) => !sar.salesflg).map(sar =>{
          return {
            company: !sar.companyname ? sar.name : sar.companyname,
            sfid: sar.id
          }
        }))
      }
    }
  }

  const handleGetDraftOpportunity = async(pid: string) =>{
    if(clear) return;
    const res = await getDraftOpportunityById(pid);
    if (res.status === 200){
      const draft = JSON.parse(res.data.record.draft);
      if(draft.closedate) setValue('closedate', dayjs(draft.closedate));
      if(draft.kiboukingakuSuchi) setValue('kiboukingakuSuchi', Number(draft.kiboukingakuSuchi).toLocaleString());
      if(draft.type) setValue('type', draft.type);
      if(draft.tashariyoureki) setValue('tashariyoureki', draft.tashariyoureki);
      if(draft.riyoucompany) setValue('riyoucompany', draft.riyoucompany);
      if(draft.goyoubou) setValue('goyoubou', draft.goyoubou);
      setValue('companies', draft.companies.map((com) =>{
        if(com.kaisyuyoteibi) com.kaisyuyoteibi = dayjs(com.kaisyuyoteibi);
        if(com.seikyuukingaku) com.seikyuukingaku = Number(com.seikyuukingaku).toLocaleString();
        return com;
      }));
      setInitialCompanyIds(draft.companies.map((com) => {return {label: com.company, id: com.sfid}}));
      setValue('otherCompanies', draft.otherCompanies.map((com) =>{
        return com;
      }));
      if(draft.otherCompanies.length > 0) setInitialOtherCompanyIds(draft.otherCompanies.map((com) => {return {label: com.company, id: com.sfid}}));
    }
  }

  const {id} = useParams();

  useEffect(() => {
    setValue('user_id', Cookies.get('adminCurrentUserId'));
    handleGetAccountsReceivables();
    if(returnInfo){
      setValue('closedate', dayjs(returnInfo.inputs.closedate.$d));
      setValue('kiboukingakuSuchi', returnInfo.inputs.kiboukingakuSuchi);
      setValue('type', returnInfo.inputs.type);
      setValue('tsuutyou', returnInfo.inputs.tsuutyou);
      setValue('otherFile', returnInfo.inputs.otherFile);
      setValue('goyoubou', returnInfo.inputs.goyoubou);
      setValue('companies', returnInfo.inputs.companies.map((com) =>{
        if(com.kaisyuyoteibi) com.kaisyuyoteibi = dayjs(com.kaisyuyoteibi.$d);
        return com;
      }));
      setInitialCompanyIds(returnInfo.inputs.companies.map((com) => {return {label: com.company, id: com.sfid}}));
      setValue('otherCompanies', returnInfo.inputs.otherCompanies.map((com) =>{
        return com;
      }));
      setInitialOtherCompanyIds(returnInfo.inputs.otherCompanies.map((com) => {return {label: com.company, id: com.sfid}}));
    }else{
      if(selectedId) {
        handleGetOpportunity(selectedId);
      }else if(draftId){
        handleGetDraftOpportunity(draftId);
      }
    }
    setIsLoad(true);
  }, [])

  useEffect(() => {
    if(!isLoad) return;
    if(pastCompany.length == 0) return;
    setInitialCompanyIds(pastCompany.map((com) => {return {label: com.company, id: com.sfid}}));
    setValue('companies', pastCompany);
  }, [isLoad, pastCompany.length]);

  useEffect(() =>{
    if(!isLoad) return;
    if(pastOtherCompany.length == 0) return;
    setInitialOtherCompanyIds(pastOtherCompany.map((com) => {return {label: com.company, id: com.sfid}}));
    setValue('otherCompanies', pastOtherCompany);
  }, [isLoad, pastOtherCompany.length]);


  const onSubmit = (evt) =>{
    const dateKeys = Object.keys(kaisyuyoteibiError);
    if(dateKeys.length > 0){
      let isError = false;
      dateKeys.forEach((k) =>{
        if(kaisyuyoteibiError[k].length > 0) isError = true;
      })
      if(isError) return;
    }
    if(closedateError.length > 0) return;
    const editId = type == FactoringFormTypes.update ? id : '';
    if(draftId){
      navigate("/admin/factoring/app/confirm?draftId=" + draftId, {state: {inputs: evt}});
    }else if(selectedId){
      navigate("/admin/factoring/app-2/confirm?selectedId=" + selectedId, {state: {inputs: evt}});
    }else{
      navigate("/admin/factoring/app/confirm", {state: {inputs: evt}});
    }
  }

  const onError = (evt) =>{
    const fields = [
      'closedate', 'kiboukingakuSuchi', 'goyoubou', 'companies', 'otherCompanies', 'tsuutyou', 'otherFile'
    ]
    const keys = Object.keys(evt);
    let targetKey = '';
    for(let i = 0; i < fields.length; i++){
      if(keys.find(k => k == fields[i])){
        targetKey = fields[i];
        break;
      }
    }
    const offset = window.innerWidth <= 768 ? -162 : -75;
    if(targetKey == 'companies' || targetKey == 'otherCompanies'){
      for(let i = 0; i < evt[targetKey].length; i++){
        if(evt[targetKey][i]){
          const childKeys = Object.keys(evt[targetKey][i]);
          scroller.scrollTo(`${targetKey}.${i}.${childKeys[0]}`, {
            smooth: true,
            offset: offset
          })
          return;
        }
      }
    }else{
      scroller.scrollTo(targetKey, {
        smooth: true,
        offset: offset
      })
    }
  };

  const handleModalOpen = () => {
    setModalOpen(!modalOpen);
  }

  const onClickDraftSave = async () =>{
    handleOpenConfirmModal();
  }

  const onClickCancel = ()=>{
    if(selectedId){
      navigate("/admin/factoring/app_list");
    }else{
      navigate("/admin/factoring/top");
    }
  }; 
  return (
    <Box sx={{pr: {xs: 1, md: 2}, pl: {xs: 1, md: 2}, pb: 2}}>
      <form id="user-edit-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <Controller
          control={control}
          name="user_id"
          render={({ field }) => (
            <input
              {...field}
              type="hidden"
            />
          )}
        />
        <Box sx={{
          borderLeft: "5px solid #D16960",
          textAlign: 'left'
        }}>
          <Typography sx={{fontWeight: 'bold', fontSize: 20, ml: 3}}>申請情報</Typography>
          <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3}}>ご希望の取引内容をご入力ください</Typography>
          <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3, color: "#FF0000"}}></Typography>
        </Box>
        <Box
          sx={{backgroundColor: '#F4F6F9', borderRadius: '0px 10px 10px 10px', pt: {xs: 2}, pb: 2, pr: 3, pl: 3, minWidth: {md: '520px'}}}
        >
          <Controller
            control={control}
            name='closedate'
            rules={validationRules['closedate']}
            render={({ field, fieldState }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    調達希望日
                  </InputLabel>
                </Grid>
                <Grid item md={4.5} sm={12} xs={12} sx={{pr: 2}}>
                  <Element name='closedate'></Element>
                  <FormControl margin='normal' fullWidth error={fieldState.invalid} sx={{mt: {xs: 1, md: 2}}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} dateFormats={{ monthAndYear: "YYYY年 MM月", monthShort: "MM月" }}>
                      <DatePicker
                        {...field}
                        format={'YYYY/MM/DD'}
                        onChange={(newValue) =>{
                          if(newValue){
                            if(closedateError && closedateError == '調達希望日を入力してください。'){
                              setClosedateError('');
                            }
                            setValue('closedate', newValue);
                          }else {
                            setClosedateError('調達希望日を入力してください。');
                          }
                        }}
                        slotProps={{textField: {helperText: closedateError, error: !!closedateError}}}
                        onError={(evt) => {
                          let message = '';
                          switch(evt){
                            case 'invalidDate': {
                              message = '日付の形式が不正です。';
                              break;
                            }
                            case 'minDate': {
                              message = '過去の日付は選択できません。';
                              break;
                            }
                            default: {
                              if(closedateError == '調達希望日を入力してください。'){
                                message = '調達希望日を入力してください。'
                              }else{
                                message = '';
                              }
                              break;
                            } 
                          }
                          setClosedateError(message);
                        }}
                        dayOfWeekFormatter={(weekday) => `${weekdayTexts[weekday]}`}
                        slots={{
                          toolbar: CustomDatePickerToolbar
                        }}
                      />
                    </LocalizationProvider>
                    <FormHelperText>{fieldState.error?.message}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={4.5} sm={0} xs={0} sx={{pr: 2}}></Grid>
              </Grid>
            )}
          />
          <Controller
            control={control}
            name="kiboukingakuSuchi"
            rules={validationRules['kiboukingakuSuchi']}
            render={({ field, fieldState }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    調達希望金額
                  </InputLabel>
                </Grid>
                <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}>
                  <Element name='kiboukingakuSuchi'></Element>
                  <TextField
                    {...field}
                    fullWidth
                    sx={{mt: {xs: 1, md: 2}}}
                    margin="normal"
                    color="secondary"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    onBlur={(e) =>{
                      setValue('kiboukingakuSuchi', Number(e.target.value.replace(/,/g, '')).toLocaleString());
                    }}
                    onFocus={(e) =>{
                      if(e.target.value){
                        setValue('kiboukingakuSuchi', e.target.value.replace(/,/g, ''));
                      }
                    }}
                  />
                </Grid>
                <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}></Grid>
              </Grid>
            )}
          />
          <Controller
            control={control}
            name="type"
            rules={validationRules['type']}
            render={({ field, fieldState }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    契約方法
                  </InputLabel>
                </Grid>
                <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}>
                  <Element name='type'></Element>
                  <FormControl fullWidth margin='normal' sx={{mt: {xs: 1, md: 2}}} error={fieldState.invalid}>
                    <Select
                      {...field}
                      color="secondary"
                    >
                      <MenuItem value='' sx={{color:'gray'}}>選択してください</MenuItem>
                      <MenuItem value={'2者間'}>2者間</MenuItem>
                      <MenuItem value={'3者間'}>3者間</MenuItem>
                    </Select>
                    <FormHelperText>{fieldState.error?.message}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}></Grid>
              </Grid>
            )}
          />
          <Controller
            control={control}
            name="goyoubou"
            defaultValue=""
            rules={validationRules['goyoubou']}
            render={({ field, fieldState }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    ご要望等
                  </InputLabel>
                </Grid>
                <Grid item md={9} sm={12} xs={12} sx={{pr: 2}}>
                  <Element name='goyoubou'></Element>
                  <TextField
                    {...field}
                    fullWidth
                    sx={{mt: {xs: 1, md: 2}}}
                    margin="normal"
                    color="secondary"
                    multiline
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    rows={3}
                  />
                </Grid>
              </Grid>
            )}
          />
        </Box>
          {fields.map((field, index) =>(
            <Box key={`comp_${index}`}>
            <Box sx={{
              borderLeft: "5px solid #D16960",
              textAlign: 'left',
              mt: 2
            }}>
              <Typography sx={{fontWeight: 'bold', fontSize: 20, ml: 3}}>売掛先情報（{index + 1}）</Typography>
              <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3}}>売却したい売掛金の情報をご入力ください</Typography>
              <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3, color: "#FF0000"}}></Typography>
            </Box>
            <Box sx={{backgroundColor: '#F4F6F9', borderRadius: '0px 10px 10px 10px', pt: {xs: 2}, pb: 2, pr: 3, pl: 3, minWidth: {md: '520px'}}}>
            <Box key={field.id} sx={{backgroundColor: '#F4F6F9', mb: '-30px'}}>
              <Controller
                control={control}
                name={`companies.${index}.company`}
                rules={validationRules['company']}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={accountsReceivables.map((option) => {
                      const values = getValues();
                      const selectedIds = values.companies.map(c => c.sfid);
                      const selectedString = selectedIds.indexOf(option.id) >= 0 ? ' (選択済み)' : '';
                      const compName = option.companyname ? option.companyname : option.name;
                      const remarkString = ` (締日：${option.closingdate}、支払日：${option.paymentdate})`;
                      return {label: compName + remarkString, id: option.id, optLabel: selectedString ? compName + selectedString : compName + remarkString}})
                    }
                    renderOption={(props, option) =>(
                      <Box component="li" {...props}>
                        {option.optLabel}
                      </Box>
                    )}
                    onChange={(_event, value) =>{
                      setValue(`companies.${index}.company`, value ? value.label : '');
                      setValue(`companies.${index}.sfid`, value ? value.id : '');
                    }}
                    onInputChange={(_event, value, reason) =>{
                      if(reason == 'input'){
                        setValue(`companies.${index}.company`, '');
                        setValue(`companies.${index}.sfid`, '');
                      }
                    }}
                    defaultValue={initialCompanyIds[index]}
                    renderInput={(params) =>(
                      <Grid container>
                        <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                          <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                            会社名
                          </InputLabel>
                        </Grid>
                        <Grid item md={9} sm={12} xs={12} sx={{pr: 2}}>
                          <Element name={`companies.${index}.company`}></Element>
                          <TextField
                            {...params}
                            {...field}
                            fullWidth
                            sx={{mt: {xs: 1, md: 2}}}
                            margin="normal"
                            color="secondary"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        </Grid>
                      </Grid>
                    )}
                    PaperComponent={({children}) =>{
                      return (
                        <Paper>
                          {children}
                          <Button
                            color="secondary"
                            fullWidth
                            onMouseDown={() => handleModalOpen()}
                            sx={{ justifyContent: "flex-start", pl: 2 }}
                          >
                            新しい売掛先を登録
                          </Button>
                        </Paper>
                      );
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name={`companies.${index}.sfid`}
                render={({ field }) => (
                  <Hidden
                  />
                )}
              />
              <Controller
                control={control}
                name={`companies.${index}.seikyuukingaku`}
                rules={validationRules['seikyuukingaku']}
                render={({ field, fieldState }) => (
                  <Grid container>
                    <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                      <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                        請求金額
                      </InputLabel>
                    </Grid>
                    <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}>
                      <Element name={`companies.${index}.seikyuukingaku`}></Element>
                      <TextField
                        {...field}
                        fullWidth
                        sx={{mt: {xs: 1, md: 2}}}
                        margin="normal"
                        color="secondary"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                        onBlur={(e) =>{
                          setValue(`companies.${index}.seikyuukingaku`, Number(e.target.value.replace(/,/g, '')).toLocaleString());
                        }}
                        onFocus={(e) =>{
                          if(e.target.value){
                            setValue(`companies.${index}.seikyuukingaku`, e.target.value.replace(/,/g, ''));
                          }
                        }}
                      />
                    </Grid>
                    <Grid item md={4.5} sm={6} xs={6} sx={{pr: 2}}></Grid>
                  </Grid>
                )}
              />
              <Controller
                control={control}
                name={`companies.${index}.kaisyuyoteibi`}
                rules={validationRules['kaisyuyoteibi']}
                render={({ field, fieldState }) => (
                  <Grid container>
                    <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                      <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                        売掛先からの入金予定日
                      </InputLabel>
                    </Grid>
                    <Grid item md={4.5} sm={12} xs={12} sx={{pr: 2}}>
                      <Element name={`companies.${index}.kaisyuyoteibi`}></Element>
                      <FormControl margin='normal' fullWidth error={fieldState.invalid} sx={{mt: {xs: 1, md: 2}}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} dateFormats={{ monthAndYear: "YYYY年 MM月", monthShort: "MM月" }}>
                          <DatePicker
                            {...field}
                            format={'YYYY/MM/DD'}
                            onChange={(newValue) =>{
                              if(newValue){
                                if(kaisyuyoteibiError[index] && kaisyuyoteibiError[index] == '入金予定日を入力してください。'){
                                  kaisyuyoteibiError[index] = '';
                                }
                                setValue(`companies.${index}.kaisyuyoteibi`, newValue);
                              }else {
                                kaisyuyoteibiError[index] = '入金予定日を入力してください。';
                              }
                              setKaisyuyoteibiError(kaisyuyoteibiError);
                            }}
                            slotProps={{textField: {helperText: kaisyuyoteibiError[index], error: !!kaisyuyoteibiError[index]}}}
                            onError={(evt) => {
                              switch(evt){
                                case 'invalidDate': {
                                  kaisyuyoteibiError[index] = '日付の形式が不正です。';
                                  break;
                                }
                                case 'minDate': {
                                  kaisyuyoteibiError[index] = '過去の日付は選択できません。';
                                  break;
                                }
                                default: {
                                  if(kaisyuyoteibiError[index] == '入金予定日を入力してください。'){
                                    kaisyuyoteibiError[index] = '入金予定日を入力してください。'
                                  }else{
                                    kaisyuyoteibiError[index] = '';
                                  }
                                  break;
                                } 
                              }
                              setKaisyuyoteibiError(kaisyuyoteibiError);
                            }}
                            dayOfWeekFormatter={(weekday) => `${weekdayTexts[weekday]}`}
                            slots={{
                              toolbar: CustomDatePickerToolbar
                            }}
                          />
                        </LocalizationProvider>
                        <FormHelperText>{fieldState.error?.message}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item md={4.5} sm={0} xs={0} sx={{pr: 2}}></Grid>
                  </Grid>
                )}
              />
              <Controller
                control={control}
                name={`companies.${index}.seikyuusyo`}
                rules={validationRules['seikyuusyo']}
                render={({ field: {onChange, value }, fieldState}) =>(
                  <Grid container>
                    <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                      <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                        請求書
                      </InputLabel>
                      <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                        請求書、注文書、契約書など売掛金の内容が分かる書類をご提出ください
                      </InputLabel>
                    </Grid>
                    <Grid item md={9} sm={12} xs={12} sx={{pr: 2}}>
                      <Element name={`companies.${index}.seikyuusyo`}></Element>
                      <FormControl margin='normal' fullWidth error={fieldState.invalid} sx={{mt: {xs: 1, md: 2}}}>
                        <DragDropFileUpload onFileUpload={onChange} input_id={1} inputValue={value}/>
                        <FormHelperText>{fieldState.error?.message}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
              />
            </Box>
            <IconButton disabled={fields.length == 1} onClick={() =>remove(index)} sx={{position: 'relative', left: '50%', bottom: 200, ml: '15px'}}>
              <CloseIcon />
            </IconButton>
            </Box>
            </Box>
          ))}
        <Box sx={{backgroundColor: '#E1E4EA', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px'}}>
          <Button
            type="button"
            variant="contained"
            onClick={() => append(defaultCompValue)}
            sx={{borderRadius: 5, width: {md: '30%', xs: '80%'}, fontWeight: 'bold', mt: {xs: 1, md: 2}, mb: {xs: 1, md: 2}}}
          >
            + 売掛先を追加する
          </Button>
        </Box>
        <Box sx={{
          borderLeft: "5px solid #D16960",
          textAlign: 'left',
          mt: 2
        }}>
          <Typography sx={{fontWeight: 'bold', fontSize: 20, ml: 3}}>その他取引先情報</Typography>
          <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3}}>その他お取引のある会社情報をご入力ください</Typography>
          <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3, color: "#FF0000"}}></Typography>
        </Box>
        <Box
          sx={{backgroundColor: '#F4F6F9', borderRadius: '0px 10px 10px 10px', pt: {xs: 2}, pb: 2, pr: 3, pl: 3, minWidth: {md: '520px'}}}
        >
          {otherCompanies.map((field, index) =>(
            <Box key={field.id} sx={{backgroundColor: '#F4F6F9'}}>
              {index > 0 ? <Divider/> : <></>}
              <Controller
                control={control}
                name={`otherCompanies.${index}.company`}
                rules={validationRules['otherCompany']}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={accountsReceivables.map((option) => {return {label: option.companyname ? option.companyname : option.name, id: option.id}})}
                    onChange={(_event, value) =>{
                      setValue(`otherCompanies.${index}.company`, value ? value.label : '');
                      setValue(`otherCompanies.${index}.sfid`, value ? value.id : '');
                    }}
                    onInputChange={(_event, value, reason) =>{
                      if(reason == 'input'){
                        setValue(`otherCompanies.${index}.company`, '');
                        setValue(`otherCompanies.${index}.sfid`, '');
                      }
                    }}
                    defaultValue={initialOtherCompanyIds[index]}
                    renderInput={(params) => (
                      <>
                        <Grid container sx={{mb: '-30px'}}>
                          <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                            <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                              会社名
                            </InputLabel>
                          </Grid>
                          <Grid item md={9} sm={12} xs={12} sx={{pr: 2}}>
                            <Element name={`otherCompanies.${index}.company`}></Element>
                            <TextField
                              {...params}
                              {...field}
                              fullWidth
                              sx={{mt: {xs: 1, md: 2}}}
                              margin="normal"
                              color="secondary"
                              error={fieldState.invalid}
                              helperText={fieldState.error?.message}
                            />
                          </Grid>
                        </Grid>
                        <IconButton disabled={otherCompanies.length == 1} onClick={() =>otherRemove(index)}  sx={{position: 'relative', left: '50%', bottom: '25px', ml: '15px'}}>
                          <CloseIcon />
                        </IconButton>
                      </>
                    )}
                    PaperComponent={({children}) =>{
                      return (
                        <Paper>
                          {children}
                          <Button
                            color="secondary"
                            fullWidth
                            onMouseDown={() => handleModalOpen()}
                            sx={{ justifyContent: "flex-start", pl: 2 }}
                          >
                            新しい売掛先を登録
                          </Button>
                        </Paper>
                      );
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name={`otherCompanies.${index}.sfid`}
                render={({ field }) => (
                  <Hidden
                  />
                )}
              />
            </Box>
          ))}
        </Box>
        <Box sx={{backgroundColor: '#E1E4EA', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px'}}>
          <Button
            type="button"
            variant="contained"
            onClick={() => otherAppend(defaultOtherCompValue)}
            sx={{borderRadius: 5, width: {md: '30%', xs: '80%'}, fontWeight: 'bold', mt: {xs: 1, md: 2}, mb: {xs: 1, md: 2}}}
          >
            + 別の会社情報を追加する
          </Button>
        </Box>
        <Box sx={{
          borderLeft: "5px solid #D16960",
          textAlign: 'left',
          mt: 2
        }}>
          <Typography sx={{fontWeight: 'bold', fontSize: 20, ml: 3}}>提出書類</Typography>
          <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3}}>審査に必要な書類をご提出ください</Typography>
          <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3, color: "#FF0000"}}></Typography>
        </Box>
        <Box
          sx={{backgroundColor: '#F4F6F9', borderRadius: '0px 10px 10px 10px', pt: {xs: 2}, pb: 2, pr: 3, pl: 3, minWidth: {md: '520px'}}}
        >
          <Controller
            control={control}
            name='tsuutyou'
            rules={validationRules['tsuutyou']}
            render={({ field: {onChange, value }, fieldState}) =>(
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    通帳（表紙付直近2か月分）
                  </InputLabel>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                    売掛先から入金がある通帳をご提出ください
                  </InputLabel>
                </Grid>
                <Grid item md={9} sm={12} xs={12} sx={{pr: 2}}>
                  <Element name='tsuutyou'></Element>
                  <FormControl margin='normal' fullWidth error={fieldState.invalid} sx={{mt: {xs: 1, md: 2}}}>
                    <DragDropFileUpload onFileUpload={onChange} input_id={1} inputValue={value}/>
                    <FormHelperText>{fieldState.error?.message}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          />
          <Controller
            control={control}
            name='otherFile'
            render={({ field: {onChange, value }}) =>(
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    その他書類
                  </InputLabel>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                    日報や納品書など売掛金の存在を確認できる補足書類がございましたらご提出ください
                  </InputLabel>
                </Grid>
                <Grid item md={9} sm={12} xs={12} sx={{pr: 2}}>
                  <Element name='tsuutyou'></Element>
                  <FormControl margin='normal' fullWidth sx={{mt: {xs: 1, md: 2}}}>
                    <DragDropFileUpload onFileUpload={onChange} input_id={1} inputValue={value}/>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          />
        </Box>
        <Box sx={{position: 'sticky', bottom: 3, width: '100%', left: {xs: 0, md: '15vh'}, mt: 2}}>
          {/* <Button
            variant='contained'
            color='warning'
            onClick={() => onClickCancel()}
            sx={{borderRadius: 5, width: {md: '20%', xs: '0%'}, minWidth: '120px', fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}, mr: 1}}
          >
            キャンセル
          </Button> */}
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleOpenSelectConfirmModal()}
            sx={{borderRadius: 5, width: {md: '30%', xs: '45%'}, minWidth: '120px', fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}, mr: 1}}
          >
            一時保存
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{borderRadius: 5, width: {md: '30%', xs: '45%'}, minWidth: '120px', fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}, mr: 1}}
          >
            次へ
          </Button>
        </Box>
      </form>
      <AccountsReceivableModal modalOpen={modalOpen} compEvent={setAccountsReceivables}/>
      {isLoading ? <Loading inverted={isLoading}/> : <></>}
      <ErrorModal message={errorMessage} modalOpen={errorModalOpen}></ErrorModal>
      <ConfirmModal message={confirmMessage} modalOpen={confirmModalOpen} cancelClick={handleCancelClick} okClick={handleOkClick} closeClick={handleCloseConfirmModal} buttonLength={confirmButtonLength}></ConfirmModal>
    </Box>
  );
}